class VideoPopup {
    static instance;

    static getInstance() {
        if (!VideoPopup.instance) {
            VideoPopup.instance = new VideoPopup();
        }
        return VideoPopup.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-video-popup.default', ($scope) => {
            $scope.find('.opal-video-popup a.elementor-video-popup').magnificPopup({
                type: 'iframe',
                removalDelay: 500,
                midClick: true,
                closeBtnInside: true,
                callbacks: {
                    beforeOpen: function () {
                        this.st.mainClass = this.st.el.attr('data-effect');
                    }
                },
            });

            if ($('.elementor-video-popup', $scope).length > 0) {
                $('.elementor-video-popup', $scope).mousefollower({
                    followerSelector: ".elementor-video-icon"
                });
            }

        });
    }
}

VideoPopup.getInstance();