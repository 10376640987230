class Elementor_Wavify {
    static instance;

    static getInstance() {
        if (!Elementor_Wavify.instance) {
            Elementor_Wavify.instance = new Elementor_Wavify();
        }
        return Elementor_Wavify.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-wavify.default', ($scope) => {
            this.wavify($scope);
        });
    }

    wavify($scope) {
        let $item = $scope.find('.data');
        $item.each((index, element) => {
            $(element).find('.wavify').wavify({
                height   : $(element).data('height'),
                bones    : $(element).data('bones'),
                amplitude: $(element).data('amplitude'),
                color    : $(element).data('color'),
                speed    : $(element).data('speed')
            });
        });
    }
}

Elementor_Wavify.getInstance();